.whole-page {
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  .da-grid-edit {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  
  .menu-edit {
    position: sticky;
    bottom: 0;
    align-self: flex-end;
    color: white;
    text-align: left;
    font-size: 1.8vw;
    font-family: 'Roboto mono';
    font-display: swap;
    margin-left: 1vw;
    font-weight: 100;
  }
  
  .title-edit {
    position: sticky;
    top: 0;
    align-self: flex-start;
    color: white;
    font-family: 'DJGROSS';
    font-display: swap;
    text-align: end;
    text-shadow: -8px -8px 0 blue, 8px -8px 0 red, -8px 8px 0 red, 8px 8px 0 red;
    margin-right: 2vw;
    margin-top: -2vw;
  }
  
  .scroll-container {
    overflow-y: auto;
    scroll-behavior: smooth;
    padding-right: 1vw;
    padding-left: 1vw;
  }
  
  .videos-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5vw;
  }
  
  .vid-container {
    position: relative;
    width: 33vw;
    height: 33vw;
    margin-top: 3vw;
    margin-bottom: 1vw;
  }
  
  .vid {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .video-wrapper {
    position: absolute;
    top: 10%;
    left: 4.9%;
    width: 90%;
    height: 85%;
  }
  
  .video-wrapper iframe {
    width: 98%;
    height: 83%;
  }

  .menu-edit h2.current::before {
    content: "/";
    animation: caretAnimation 1s infinite;
}

@media only screen and (max-width: 767px) {

  .da-grid-edit {
    display: grid;
    grid-template-columns: none;
    justify-content: center;
  }

  .scroll-container{
    order: -1;
    margin-bottom: 20vw;
    margin-top: 10vw;
  }

  .title-edit {
    order: -2;
    align-self: center;
    position:static;
  }

  .menu-edit {
    text-align: center;
    font-size: 5vw;
    align-self: end;
    justify-self: center;
    position:static;
    margin-top: 10vw;
  }

  .menu-edit h2.current::before {
      content: none;
  }

  .vid-container {
    position: relative;
    width: 70vw;
    height: 70vw;
    margin-top: 20vw;
    margin-bottom: 1vw;
  }

  .scroll-container {
    overflow-y:hidden;
    scroll-behavior:unset;
    padding-right: 1vw;
    padding-left: 1vw;
  }

}
  