.phone-view {
    position: relative;
    max-width: 40vw;
    max-height: 40vw;
    border: 3px solid white;
    align-self: center;
}

.phone-view::before {
    content: "";
    filter: blur(1.5px);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #55e24b;
    background-image:
        linear-gradient(rgba(255, 255, 255, 0.816) 0.5px, transparent 0.5px),
        linear-gradient(90deg, rgba(255, 255, 255, 0.816) 0.5px, transparent 0.5px);
    background-size: 8px 8px;
    z-index: -1;
}

.battery {
    grid-column-start: 4;
    width: 2vw;
    height: 3vw;
}

.header {
    padding-top: 0.5vw;
    padding-bottom: 0.2vw;
    display: grid;
    grid-template-columns: 1fr 2fr 8fr 1fr;
    background-color: #6eaf41;
    background-size: 8px 8px;
    z-index: -1;
    border-bottom: solid 0.2vw black;
}

.header::before {
    filter: blur(1.5px);
}

.header-text {
    color: #ffffff;
    font-weight: bold;
    font-size: 1vw;
}

.signal-top {
    margin-left: 0.5vw;
    grid-column-start: 1;
  }

  .signal-img {
    width: 130%;
    height: 80%;
  }

.signal-bot {
    align-self: end;
}

.contacts {
    margin-left: 1vw;
    font-size: 3vw;
    align-self: end;
    font-family: 'VT323', monospace;
    font-display: swap;
    white-space: nowrap;
}

.hour {
    margin-right: 0.5vw;
    margin-top: -0.5vw;
    justify-self: end;
    font-size: 2vw;
    align-self: start;
    font-family: 'VT323';
}

.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2.5vw;
    padding: 1.5vw;
}

.grid-item {
    background-color: transparent;
    padding: 4vw;
    text-align: center;
    font-size: 1vw;
    color: #55e24b;
}

.icon {
    align-self: center;
    justify-self: center;
    height: 7vw;
    width: 7vw;

}

.fb {
    height: 9vw;
    width: 9vw;
}

.email {
    height: 9.5vw;
    width: 9.5vw;
    cursor:pointer;
    margin-right: -1vw;
}

.lin{
    margin-right: -1vw;
}

.icon-text-lin
{
    margin-right: -1vw;
}

.phone{
    cursor:pointer;
}

.link {
    height: 8vw;
    width: 8vw;
    text-decoration: none;
    color: white;
    
}

.icon-text{
    font-size: 1.7vw;
    font-family: 'VT323', monospace;
    margin-top: 0;
    color: #fff;
    margin-bottom: -0.5vw;
}

.icon-text-email{
    margin-top: -1.5vw;
    margin-right: -1vw;

}

.grid-icon-email{
    margin-top: -1vw;
    cursor: pointer;
}

.icon-text-fb{
    margin-top: -1vw;
}

.grid-icon-twitter{
    margin-bottom: -1.5vw;
}

.contact-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: center;
    width: 25vw;
    height: 20vw;
    background-color: #6eaf41;
    color: white;
    border-radius: 8px;
    font-family: 'VT323', monospace;
  }
  
  .contact-modal h1{
    margin-top: 1vw;
    font-size: 3.5vw;
    margin-bottom: 0;
    text-align: center;
  }

  .contact-modal p{
    margin-top: 0;
    font-size: 2vw;
    text-align: center;
    color: black;
  }

  .contact-modal-buttons{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3vw;
  }

  .contact-modal-buttons button {
    border:groove;
    background-color: #6eaf41;
    color: white;
    font-family: 'VT323', monospace;
    font-size: 3vw;
    padding: 0.2vw;
    outline: 1vw;
    cursor: pointer;
    image-rendering: pixelated;
    margin-bottom: 1vw;
  }

  .copy-button{
    background-color: #5391B0 !important;
}

@media only screen and (max-width: 767px) {

    .phone-view {
        order: -1;
        justify-self: center;
        max-width: 80vw;
        max-height: 75vw;
        margin-top: 25vw;
        padding-bottom: 20vw;
        margin-bottom: 25vw;

      }
    
      .title {
        order: -2;
      }

      .icon {
        height: 15vw;
        width: 15vw;
    }

    .fb {
        height: 18vw;
        width: 18vw;
        margin-top: -1.5vw;
    }

    .icon-text-fb{
        margin-top: -0.5vw;
    }
    
    .email {
        height: 19vw;
        width: 19vw;
        margin-top: -1.5vw;
        margin-right: 0vw;
    }

    .icon-text{
        font-size: 4vw;
        margin-bottom: 6vw;
    }

    .contacts {
        margin-left: 3vw;
        font-size: 7vw;
    }

    .signal-img {
        width: 200%;
        height: 95%;
    }

    .hour {
    margin-right: 1vw;
    margin-top: -1vw;
    font-size: 5.5vw;
    }

    .grid {
        grid-gap: 0.5vw;
        padding: 1.5vw;
        padding-top: 5vw;
    }

    .battery {
        width: 7vw;
        height: 10vw;
    }

    .lin{
        margin-right: 0vw;}
    
    .icon-text-lin{
        margin-right: 0vw;}

    .contact-modal {
        position: fixed;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 70vw;
        height: 50vw;
        }
        
        .contact-modal h1{
        margin-top: 1vw;
        font-size: 10vw;
        margin-bottom: 0;
        text-align: center;
        }
    
        .contact-modal p{
        margin-top: 2vw;
        font-size: 5.5vw;
        text-align: center;
        }

        .contact-modal-buttons button {
            font-size: 8vw;
            padding: 1vw;
            outline: 5vw;
            cursor: pointer;
            image-rendering: pixelated;
            margin-bottom: 5vw;
          }
   
  }

  