.torn-sheet {
    display: flex;
    align-items: center
}

.paper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40vw;
    width: 30vw;
    background: white;
    background: linear-gradient(to right, transparent 0, transparent 1vw, white 1vw);
}

.lines {
    height: calc(100% - 2vw);
    background-image: repeating-linear-gradient(white 0px, white 2.4vw, black 2.5vw);
}

.torn-paper {
    position: relative;
    height: 100%;
    margin-left: -1.75vw;
    /* Adjust this value as needed */
}

.torn-paper img {
    height: 100%;
}

.tape {
    position: absolute;
    height: 8%;
    top: 2.9vw;
    left: 49%;
    transform: translateX(-50%);
    opacity: 100%;
}

.text {
    line-height: 2.5vw;
    color: black;
    font-size: 1.5vw;
    text-align: left;
    overflow: hidden;
    outline: none;
    margin-left: 1vw;
    margin-right: 1.5vw;
    font-family: 'Rock Salt', 'Courier New', Courier, monospace;
    font-display: swap;
}

.text h2 {
    font-size: 3vw;
}

.text-2 {
    text-align: right;
}

.link {
    text-decoration: none;
    color: white;
}

@media only screen and (max-width: 767px) {

    .torn-sheet {
        order: -1;
        margin-top: 25vw;
        margin-bottom: 25vw;
      }

      .paper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 150vw;
        width: 85vw;
        background: white;
        background: linear-gradient(to right, transparent 0, transparent 1vw, white 1vw);
        }

    .text {
        line-height: 8.1vw;
        color: black;
        font-size: 5vw;
        text-align: left;
        overflow: hidden;
        outline: none;
        margin-left: 0vw;
        margin-right: 1.5vw;
    }

    .text h2 {
        font-size: 10vw;
    }

    .lines {
        height: calc(100% - 5vw);
        background-image: repeating-linear-gradient(white 0px, white 8vw, black 8.1vw);
    }

    .tape {
        display: none;
      }
    
  }