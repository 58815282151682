.whole-page {
    background-color: rgba(0, 0, 0, 0.4);
}

.da-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    min-height: 100vh;
    height: 100%;
}

.tv-static {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 5);

}


.tv-static video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    object-fit: cover;
    animation: staticEffect 5s infinite;
}

@keyframes staticEffect {
    0% {
        opacity: 1;
    }

    20% {
        opacity: 1;
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ivan {
    width: 35vw;
    height: 43vw;
}

.title {
    align-self: start;
    color: white;
    font-family: 'DJGROSS';
    font-display: swap;
    text-align: end;
    text-shadow:
        -8px -8px 0 blue,
        8px -8px 0 red,
        -8px 8px 0 red,
        8px 8px 0 red;
    margin-right: 3vw;
    margin-top: -3vw;
}

.menu {
    align-self: end;
    color: white;
    text-align: left;
    font-size: 1.8vw;
    font-family: 'Roboto mono';
    font-display: swap;
    margin-left: 1vw;
    font-weight: 100;
}

.medec {
    font-size: 4.5vw;
}

.current {
    color: yellow;
}

.menu h2.current::before {
    content: "/";
    animation: caretAnimation 1s infinite;
}

@keyframes caretAnimation {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.link {
    text-decoration: none;
    color: white;
}

@media only screen and (max-width: 767px) {
    .da-grid {
        display: grid;
        grid-template-columns: none;
        justify-content: center;
    }

    .image-container {
        order: -1;
      }
    
      .title {
        order: -2;
        margin-bottom: 10vw;
      }
      .medec{
        font-size: 15vw;
        text-align: center;
      }

      .ivan {
        aspect-ratio: 1 / 1;
    }   

    .menu {
        text-align: center;
        font-size: 5vw;

    }
    .menu h2.current::before {
        content: none;
    }
    .ivan{
        width: 85vw;
        height: 100vw;
        margin-top: 25vw;
        margin-bottom: 25vw;
    }
  }